<template>
	<div id="Introduction">
		<!-- 项目介绍 -->
		<div class="banner">
			<el-image class="background" :src="require('@/assets/imgs/banner2.jpeg')" fit='cover'></el-image>
			<b class="mask"></b>
			<div class="main">
				<img class="ban" src="../../assets/imgs/banner.png" alt="">
				<div class="cont">
					<p>项目介绍</p>
					<!-- <b>项目周期：2024年3月-2025年4月</b> -->
					<b>指导发起单位：中国初级卫生保健基金会</b>
					<b>执行单位：北京生命绿洲公益服务中心</b>
					<b>鸣谢支持：<img :src="require('@/assets/imgs/QL_logo2.png')" alt=""></b>
				</div>
			</div>
			<!-- <img class="frame" src="../../assets/imgs/Frame.png" alt=""> -->
		</div>
		<div class="content">
			<ul v-if="menus">
				<li v-for="(item,i) in menus" :key="i" @click="menusActive(item.menuName)"
					:class="active == item.menuName ? 'active':''">
					<i>“</i>
					{{item.menuName}}
				</li>
			</ul>
			<router-view class="view" />
		</div>
	</div>
</template>

<script>
	import {
		ProjectPage, // 优秀稿件
		getConfig, // 首页获取logo banner 流程图  
	} from '@/request/api';
	export default {
		name: 'Introduction',
		components: {},
		data() {
			return {
				active: '项目介绍',
				menus: [],
				banner: ''
			}
		},
		created() {
			this.getConfigFun();
			this.getProjectPage();
		},
		mounted() {},
		methods: {
			menusActive(active) {
				this.active = active;
				if (active == '项目介绍') {
					this.$router.push({
						name: 'introduce'
					})
				} else if (active == '参与流程') {
					this.$router.push({
						name: 'flowPath'
					})
				} else if (active == '投稿要求') {
					this.$router.push({
						name: 'condition'
					})
				} else if (active == '特别声明') {
					this.$router.push({
						name: 'statement'
					})
				}
			},

			// 首页获取logo banner 流程图
			getConfigFun() {
				getConfig().then(res => {
					if (res.code == 200) {
						this.banner = res.data.bannerPath;
					}
				})
			},

			getProjectPage() {
				ProjectPage().then(res => {
					if (res.code == 200) {
						this.menus = res.data.menus
					}
				}).catch(err => {
					console.log(err)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#Introduction {
		display: flex;
		flex-direction: column;

		.banner {
			margin: auto;
			height: 560px;
			position: relative;
			width: 1200px;
			border-radius: 30px;
			overflow: hidden;
			margin: 20px auto 40px;

			// .frame {
			// 	position: absolute;
			// 	height: 80%;
			// 	right: -40px;
			// 	top: 50%;
			// 	transform: translateY(-50%);
			// }

			.webHander {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				z-index: 1;
			}

			.background {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 0;
			}

			.mask {
				display: none;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 0;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(252, 253, 255, 1) 100%)
			}

			.main {
				position: absolute;
				width: 1000px;
				top: 0;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				// padding-bottom: 150px;

				.ban {
					height: 100px;
					margin-left: -30px;
					margin-bottom: 100px;
				}

				.cont {
					display: flex;
					flex-direction: column;

					p {
						color: #0082CE;
						font-size: 70px;
						font-weight: 600;
						line-height: 1.5;
						font-family: sans-serif;
					}

					b {
						color: #666;
						font-size: 16px;
						font-weight: normal;
						margin-top: 12px;
						opacity: .8;
						line-height: 1.5;
						// text-shadow: 0 0 5px #333;

						img {
							height: 36px;
						}
					}
				}
			}
		}

		.content {
			display: flex;
			flex-direction: row;
			width: 1200px;
			margin: auto;
			padding-top: 30px;

			ul {
				display: flex;
				flex-direction: column;
				margin-right: 120px;

				* {
					cursor: pointer;
				}

				li {
					display: flex;
					flex-direction: row;
					font-size: 36px;
					align-items: flex-start;
					margin-bottom: 70px;

					a {
						display: block;
					}

					i {
						font-style: normal;
						font-size: 60px;
						color: #28B0A6;
						line-height: 1;
					}
				}

				.active {

					color: #28B0A6;
					transition: all .3s;
				}
			}

			.view {
				width: 900px;
				overflow: hidden;
				flex: 1;
				align-items: flex-start;
				min-height: 100px;
			}
		}
	}
</style>